/* src/ChatBot.css */
.chatbot-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .chatbot-messages {
    height: 400px;
    overflow-y: scroll;
    margin-bottom: 20px;
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .message {
    margin: 10px 0;
  }
  
  .message.user {
    text-align: right;
  }
  
  .message.bot {
    text-align: left;
  }
  
  .chatbot-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chatbot-input input {
    width: calc(100% - 80px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .chatbot-input button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chatbot-input button:hover {
    background-color: #0056b3;
  }
  