.foodmenu {
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.foodmenu__package-box {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0; /* Increase top and bottom margin for spacing */
  background-color: #f9f9f9;
  display: flex; /* Ensures image and info are aligned in a row */
  align-items: center; /* Vertically centers the content */
  max-width: 900px;
}

@media (min-width: 768px) {
  .foodmenu__package-box {
    margin: 30px 0; /* More space for larger screens */
  }
}

.foodmenu__package-box:hover {
  transform: scale(1.02);
}

.foodmenu__image {
  width: 150px; /* Increase the width as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px;
  margin-right: 15px;
}

.foodmenu__info {
  flex: 1;
}

.foodmenu__code,
.foodmenu__name,
.foodmenu__price {
  display: block;
  font-weight: bold;
}

.foodmenu__description {
  color: #555;
  font-style: italic;
  margin: 5px 0;
}

.foodmenu__price {
  color: #ff5722;
  font-size: 1.1em;
}

@media (max-width: 767px) {
  .foodmenu__package-box {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }

  .foodmenu__image {
    width: auto; /* Increased width for larger images */
    height: auto; /* Set height for uniformity */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 10px; /* Add bottom margin for spacing */
  }

  .foodmenu__info {
    text-align: center; /* Center text below the image */
    padding: 10px; /* Add padding for better spacing */
  }

  .foodmenu__code,
  .foodmenu__name,
  .foodmenu__price {
    margin: 5px 0; /* Add spacing between text elements */
  }

  .foodmenu__code {
    font-size: 1.1em; /* Slightly larger font size */
    color: #333; /* Darker color for better readability */
  }

  .foodmenu__name {
    font-size: 1.3em; /* Larger font size for the name */
    color: #ff5722; /* Attractive color for emphasis */
  }

  .foodmenu__description {
    color: #555; /* Gray for description */
    font-style: italic;
    font-size: 0.9em; /* Slightly smaller font for description */
  }

  .foodmenu__price {
    color: #ff5722; /* Keep the price color for emphasis */
    font-size: 1.2em; /* Make price a bit larger */
    margin-top: 10px; /* Add space above the price */
  }
}

.foodmenu__price {
  color: #fff; /* Text color for contrast */
  background-color: #ff5722; /* Background color */
  padding: 10px 15px; /* Padding for button effect */
  border-radius: 25px; /* Makes it round */
  display: inline-block; /* Ensures it behaves like a button */
  font-size: 1.2em; /* Font size */
  margin-top: 10px; /* Space above the price */
  text-align: center; /* Center the text */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  font-weight: bold; /* Bold text */
}

.foodmenu__price:hover {
  background-color: #e64a19; /* Darker shade on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.category-buttons {
  display: flex;
  flex-wrap: wrap; /* Wrap buttons to new lines */
  justify-content: center; /* Center buttons */
  margin-bottom: 20px; /* Space below buttons */
}

.category-button {
  background-color: #ff5722; /* Button color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 25px; /* Rounded corners */
  padding: 12px 25px; /* Padding */
  margin: 12px; /* Space between buttons */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 1.1em; /* Font size */
  font-weight: bold; /* Bold text */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.category-button:hover {
  background-color: #e64a19; /* Darker on hover */
  transform: translateY(-2px); /* Lift effect on hover */
}

.items-header {
  text-align: center; /* Center the header */
  font-size: 1.5em; /* Header size */
  color: #333; /* Header color */
  margin-bottom: 15px; /* Space below header */
  font-weight: bold; /* Bold header */
}

.items-header {
  text-align: center; /* Center the header */
  font-size: 1.8em; /* Larger header size */
  color: #fff; /* White text color for contrast */
  margin-bottom: 15px; /* Space below header */
  font-weight: bold; /* Bold header */
  padding: 10px; /* Padding for better spacing */
  background-color: #4a90e2; /* Background color for the header */
  border-radius: 8px; /* Rounded corners for the background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.filtered-items {
  background-color: #f9f9f9; /* Light background color for items */
  padding: 20px; /* Padding around the item list */
  border-radius: 8px; /* Rounded corners for the item list */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Bubble effect for buttons */
.category-button {
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 25px; /* Rounded corners */
  padding: 12px 25px; /* Padding */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 1.1em; /* Font size */
  font-weight: bold; /* Bold text */
  transition: transform 0.3s; /* Smooth transition for transform */
  position: relative; /* Position for bubble effect */
  overflow: hidden; /* Hide overflow */
}

.category-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%; /* Large width for bubble */
  height: 300%; /* Large height for bubble */
  background: rgba(255, 255, 255, 0.3); /* Light background */
  border-radius: 50%; /* Circular bubble */
  transition: transform 0.5s; /* Smooth transition for bubble */
  transform: translate(-50%, -50%) scale(0); /* Start as invisible */
  z-index: 0; /* Behind text */
}

.category-button:hover::after {
  transform: translate(-50%, -50%) scale(1); /* Scale up on hover */
}

.category-button:hover {
  transform: translateY(-3px); /* Move button up slightly on hover */
}

.button-33 {
  background-color: #c2fbd7;
  border: 1px solid darkgreen; /* Dark green outline */
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  font-size: 16px;
  font-weight: bold;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin: 5px; /* Space around buttons */
}

.button-33:hover {
  box-shadow: rgba(44, 187, 99, .35) 0 -25px 18px -14px inset, rgba(44, 187, 99, .25) 0 1px 2px, rgba(44, 187, 99, .25) 0 2px 4px, rgba(44, 187, 99, .25) 0 4px 8px, rgba(44, 187, 99, .25) 0 8px 16px, rgba(44, 187, 99, .25) 0 16px 32px;
  transform: scale(1.5) rotate(-5deg);
}

/* New CSS for Add to Cart, Quantity Input, Cart View, and Checkout */
.add-to-cart-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-to-cart-btn:hover {
  background-color: #388e3c;
}

.foodmenu__quantity-input input {
  width: 50px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  text-align: center;
  margin-left: 10px;
}

/* Cart View Styles */
.cart-view {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cart-item {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.cart-item-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
}

.cart-item-info {
  flex: 1;
}

.remove-btn {
  background-color: #e64a19;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
}

.remove-btn:hover {
  background-color: #d32f2f;
}

.cart-total {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.checkout-btn {
  background-color: #ff5722;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
}

.checkout-btn:hover {
  background-color: #e64a19;
}

.go-to-cart-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.go-to-cart-btn:hover {
  background-color: #388e3c;
}

/* Cart Styles */
.cart-container {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cart-item {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}

.cart-total {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.place-order-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
}

.place-order-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Back to Menu Button */
.back-to-menu-button {
  background-color: #ff5722;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 10px;
}

/* Hover Effects */
.place-order-button:hover,
.back-to-menu-button:hover {
  opacity: 0.8;
}
